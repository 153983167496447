@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";




































































.timeout-container {
  padding-top: $size-xl;
  padding-bottom: $size-xl;
}
