.v-step {
  box-shadow: 0 1px 6px rgba(0,0,0,0.5) !important;
  border-radius: $border-radius-m !important;
  filter: none !important;
  padding: $size-m !important;
  text-align: left !important;
  max-width: 320px !important;
  padding-top: 40px !important;

  &__stop {
    color: rgba(255,255,255,.5) !important;
    margin-left: -$size-xxs;
    font-weight: $font-weight-regular;
  }

  &__title {
    margin-bottom: $size-xs;
  }

  &__body {
    font-weight: $font-weight-light;
    color: rgba(255,255,255,.85);
  }

  .btn--float-dark {
    &::before {
      content: "";
      position: absolute;
      border-bottom: 1px dotted white;
    }
  }

  .v-step__arrow {
    &::before {
      margin-left: 0 !important;
    }
  }

  &[x-placement^=down] {
    margin-top: 12px !important;

    @include mq-l() {
      margin-top: 0 !important;
    }
  }

  &[x-placement^=left] {
    .v-step__arrow {
      border-width: 24px 0 24px 24px !important;
      right: -12px !important;
    }
  }

  &[x-placement^=top] {
    .v-step__arrow {
      border-width: 24px 24px 0 24px !important;
      bottom: -12px !important;
    }
  }

  &[x-placement^=down] {
    .v-step__arrow {
      border-width: 0 24px 24px 24px !important;
      top: -12px !important;
      border-right-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  &[x-placement^=right] {
    .v-step__arrow {
      border-width: 24px 24px 24px 0 !important;
      right: -12px !important;
    }
  }

  &__actions {
    margin-top: $size-m;
    margin-bottom: -$size-xs;
    @include flex-space-between();

    @include mq-xs() {
      margin-top: 0;
    }

    &__actions {
      @include flex-space-between();

      .btn ~ .btn {
        margin-left: $size-xs;
      }

      .btn--secondary {
        @include mq-l() {
          display: none;
        }
      }
    }
  }
}

.in-tour {
  pointer-events: none;
  overflow: hidden;
}

.tour-active {
  position: relative;
  z-index: $zindex-tooltip;
  opacity: 1 !important;
  pointer-events: none !important;
  user-select: none;

  &::before {
    border-radius: $border-radius-l;
    content: "";
    position: absolute;
    z-index: -1;
    background: $color-white;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    padding: 7px;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: "" !important;
    position: fixed !important;
    z-index: -2 !important;
    opacity: 1 !important;
    background: rgba($color-black, .4) !important;
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    transform: translate3d(0, 0, 0);
  }
}

.tour-active, .v-tour {
  pointer-events: auto;
}

.tour-active {
  top: 0;

  .btn:disabled {
    opacity: 1 !important;
  }
}
