@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";















































































@import '~@sas-te/alfabeto-vue';
@import '~@sas-te/alfabeto-vue/src/scss/packages/v-tooltip';
@import '~@sas-te/alfabeto-vue/src/scss/packages/vue-toasted';

@import './styles/spacing';
@import './styles/tour';
@import './styles/grid';

.d-flex {
  display: flex;
}

.sas-modal__container.error {
  .sas-modal {
    position: absolute !important;
    min-width: 100% !important;
    max-width: 100%;
    border-radius: $border-radius-l $border-radius-l 0 0 !important;
    bottom: 0;

    @include mq-m--mf() {
      position: relative !important;
      min-width: auto !important;
      bottom: auto;
      border-radius: $border-radius-l !important;
    }

    .sas-modal__header h3 {
      font-size: $font-size-heading-6;
      max-width: 85%;

      @include mq-s--mf() {
        font-size: $font-size-heading-3;
      }

      @include mq-m--mf() {
        max-width: 100%;
      }
    }
  }

  .content-modal-delete-teachers {
    .subtitle {
      font-size: $font-size-s;

      @include mq-s--mf() {
        font-size: $font-size-m;
      }
    }
  }
}

.wrapper-app {
  overflow: hidden;
  height: 100%;
  background-color: $color-ice;
}

.wrapper-body {
  overflow-y: auto;
  margin-top: 70px;
  height: calc(100vh - 70px);
  overflow-x: hidden;
  transition: all .4s ease-in-out;

  &.multiple-selects {
    height: calc(100vh - (70px + 92px));
  }
}

.drawer,
.footer-drawer,
.footer-drawer .buttons {
  @include mq-l--mf() {
    width: 944px !important;
    min-width: inherit !important;
  }

  &.--position-bottom,
  .--position-bottom .sas-wrapper.footer-wrapper {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.link-primary {
  font-weight: $font-weight-semi-bold;
  color: $color-primary;

  &:hover {
    text-decoration: underline;
  }
}
